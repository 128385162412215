export const environment = {
    //prueba2
    production: false,
    // API_URL: 'http://18.206.176.66:5000/',

    //Localhost
    // API_URL: 'http://localhost/',
    // API_URL: 'http://127.0.0.1:5000/',
    // API_ARCHIVO: 'http://127.0.0.1:5000/',

    //Dev
    APIRECAPTCHA: '6LfN0jweAAAAADlCqTXohyjTWBbPBOBLj-p9Tl0F',

    API_URL: 'https://sglt-test-back.totalsf.com.pe/', // Para conectar con dev back
    // API_URL: 'http://localhost:80/',  // Probar con back local

    // API_ARCHIVO: 'http://34.231.72.15/',
    API_ARCHIVO: 'https://sglt-test-back.totalsf.com.pe/?file=',
    // proxy_pass http://18.211.245.123:8089/Biblioteca/
    // API_ARCHIVO_IFRAME: 'http://18.206.176.66/',
    // API_ARCHIVO: 'http://18.206.176.66/',
    API_ARCHIVO_IFRAME: 'http://34.231.72.15/',
    URL_SOCKET: 'http://ubuntu@ec2-34-230-220-243.compute-1.amazonaws.com:81',
    // apiKey: 'AIzaSyBcFh05exECkGlpMHr98DAqfKrGZMkwyUw',
    apiKey: 'AIzaSyDPXtdww1GvqgfHy3IcbUU0pkRcdOfuWnY',
    API_CORREO: '@leasingtotal.com.pe',
    //OAUTH SERVICE
    // AUTH_URL: 'http://ec2-54-88-30-132.compute-1.amazonaws.com:3001/',
    AUTH_URL: 'https://sglt-test-back.totalsf.com.pe/',
    CLIENT_ID: 'RQX70YcvpweF289fTOPGvkyvjPBYoHxhSNI9bh7J',
    CLIENT_SECRET: 'kI3pla425RznZ7GqmIHWFyiToVVU9eexBBIbrjbweEuc8s29a4eTfiwHzZ2hz0ppWJBFWrhcG8TX0fvEWGXPOEBkuapFYmMuw3apNBnfwGH2pkN9Q4rVaYkkkPwhI0M0',
};
